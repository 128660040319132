<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5">

          <div class="px-4 mb-5">
            <p class="titulo-pag">Atores</p>
            <h1 class="mt-3">Lista de atores</h1>
            <span>Visualize a lista e detalhes dos atores</span>
          </div>

          <div class="pt-3 px-4 pb-5 bg-white rounded-3">
            <label for="searchInput" class="form-label fw-bold">Busca</label>
            <div class="d-flex justify-content-between">
              <input type="text" class="form-control busca-lista" id="searchInput" v-model="search" name="searchInput" placeholder="Digite o nome ou código que quer encontrar">
              <router-link class="btn btn-dark-blue px-4 py-2 rounded-pill" :to="{ name: 'Atores.Cadastro'}">Cadastrar novo ator</router-link>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-3 mb-4">
            <select id="ordenacao" name="ordenacao" class="form-select w-auto" aria-label="Ordenar os campos" @change="handleSortSelector">
              <option selected value="id-desc">Mais recentes</option>
              <option value="id-asc">Mais antigos</option>
              <option value="nome_do_ator-asc">Ordem alfabética crescente</option>
              <option value="nome_do_ator-desc">Ordem alfabética descrente</option>
            </select>
            <select id="tipodeator" name="tipodeator" class="form-select w-auto" aria-label="Filtra por tipo" @change="handleFilterSelector">
              <option hidden selected disabled value="">Tipo do ator</option>
              <option value="ambos">Ambos</option>
              <option value="fisico">Somente pessoas físicas</option>
              <option value="juridico">Somente organizações</option>
            </select>
          </div>

          <div class="pt-3 px-4 pb-5 bg-white rounded-3">
            <table class="table table-hover caption-top">
              <thead>
                <tr>
                  <th scope="col" class="text-center sortable th-01" @click="sortById">Código</th>
                  <th scope="col" class="sortable th-02" @click="sortByNome">Nome</th>
                  <th scope="col" class="th-03 text-center">Tipo do Ator</th>
                  <th scope="col" class="th-04 text-center">Plano de ação</th>
                  <th scope="col" class="th-04 text-center"></th>
                </tr>
              </thead>
              <tbody class="border-top-0 border-start border-end">
                <tr v-for="entrie in searchBy" :key="entrie.id" :class="{'pe-none' : typeof entrie.id !== 'number', 'invisible' :  typeof entrie.id !== 'number'}">
                  <th scope="row" class="text-center">
                    <span class="align-middle">{{ String(entrie.id).padStart(3, '0') }}</span>
                  </th>
                  <td>
                    <span class="align-middle">{{ entrie.nome_do_ator }}</span>
                  </td>
                  <td class="text-center">
                    <span class="badge rounded-pill align-middle" :class="[  entrie.tipo_do_ator === 'juridico' ? 'light-blue-organizacao' : 'blue-pessoa-fisica']">{{ entrie.tipo_do_ator === 'juridico' ? 'Organização' : 'Pessoa Física' }}</span>
                  </td>
                  <td class="text-center">
                    <!-- <span class="status-plano">Ativo</span> -->
                  </td>
                  <td>
                    <div v-if="typeof entrie.id === 'number' && !entrie.deletingState" class="options-wrapper ms-5">
                      <button class="btn btn-sm btn-options" type="button" @click="toggleOptions">
                        <svg class="pe-none" xmlns="http://www.w3.org/2000/svg" width="13" height="3" viewBox="0 0 13 3">
                          <path data-name="three-dots" d="M3,9.5A1.5,1.5,0,1,1,4.5,8,1.5,1.5,0,0,1,3,9.5Zm5,0A1.5,1.5,0,1,1,9.5,8,1.5,1.5,0,0,1,8,9.5Zm5,0A1.5,1.5,0,1,1,14.5,8,1.5,1.5,0,0,1,13,9.5Z" transform="translate(-1.5 -6.5)" fill="#717171"/>
                        </svg>
                      </button>
                      <div class="options-list">
                        <router-link v-if="typeof entrie.id === 'number' && !entrie.deletingState" type="button" class="btn btn-sm w-100 pe-4" :to="{ name: 'Atores.Visualizacao', params: { id:  entrie.id } }" >
                          <div class="row">
                            <div class="col-3 text-center"><img src="../../assets/icons/eye-fill.svg"></div>
                            <div class="col-9 text-left">Visualizar cadastro</div>
                          </div>
                          </router-link>
                        <router-link class="btn btn-sm w-100 pe-4" :class="{ 'd-none' : entrie.deletingState }" :to="{ name: 'Atores.Edicao', params: { id:  entrie.id } }">
                          <div class="row">
                            <div class="col-3 text-center">
                              <img src="../../assets/icons/pencil-fill.svg">
                            </div>
                            <div class="col-9 text-left">
                              Editar informações
                            </div>
                          </div>
                        </router-link>

                        <button class="btn btn-sm w-100 pe-4" :class="{ 'd-none' : entrie.deletingState }" type="button" @click="confirmarDelecao(entrie)">
                          <div class="row">
                            <div class="col-3 text-center">
                              <img src="../../assets/icons/trash-fill.svg">
                            </div>
                            <div class="col-9 text-left">
                              Excluir ator
                            </div>
                          </div>

                        </button>
                      </div>
                    </div>

                    <button v-if="entrie.deletingState" class="btn btn-sm ms-1" type="button">
                      Deletando ... <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <nav class="d-flex justify-content-center" aria-label="Paginadores">
              <ul class="pagination">
                <li class="page-item" :class="{ disabled : collectionPage === 1}">
                  <span v-if="collectionPage === 1" class="page-link border-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                      <g id="icon_double_caret" data-name="icon/double caret" transform="translate(15) rotate(90)">
                        <rect id="base" width="15" height="15" fill="none"/>
                        <path id="select" d="M3886.877,524.493l4.389,4.371,4.223-4.371" transform="translate(-3883.877 -519.493)" fill="none" stroke="#343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                      </g>
                    </svg>
                  </span>
                  <a v-else class="page-link border-0" @click="prev" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                      <g id="icon_double_caret" data-name="icon/double caret" transform="translate(15) rotate(90)">
                        <rect id="base" width="15" height="15" fill="none"/>
                        <path id="select" d="M3886.877,524.493l4.389,4.371,4.223-4.371" transform="translate(-3883.877 -519.493)" fill="none" stroke="#343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                      </g>
                    </svg>
                  </a>
                </li>
                <li class="page-item"><span class="page-link mx-1 user-select-none pe-none">{{ collectionPage }}</span></li>
                <li class="page-item">
                  <a class="page-link border-0" @click="next" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                      <g id="icon_double_caret" data-name="icon/double caret" transform="translate(0 15) rotate(-90)">
                        <rect id="base" width="15" height="15" fill="none"/>
                        <path id="select" d="M3886.877,524.493l4.389,4.371,4.223-4.371" transform="translate(-3883.877 -519.493)" fill="none" stroke="#343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { AtoresRepository } from '../../libs/repositories';

export default {
  name: 'AtoresListagem',
  data () {
    return {
      collectionData: [],
      collectionPage: 1,
      maxPerPage: 7,
      /** @type {'id'|'nome_do_ator'} */
      sortBy: 'id',
      /** @type {'asc'|'desc'} */
      direction: 'desc',
      /** @type {'ambos'|'fisico'|'juridico'} */
      actorType: 'ambos',
      timer: null,
      search: '',
      collection: null
    };
  },
  watch: {
    sortBy (from, to) {
      /** @type {HTMLSelectElement} */ // @ts-expect-error
      const select = document.getElementById('ordenacao');
      select.value = `${this.sortBy}-${this.direction}`;
    },
    direction (from, to) {
      /** @type {HTMLSelectElement} */ // @ts-expect-error
      const select = document.getElementById('ordenacao');
      select.value = `${this.sortBy}-${this.direction}`;
    },
    collectionPage (from, to) {
      /**
       * sempre que houver mudança no número da página (variável `collectionPage`)
       * o método que realiza a atualização dos dados será chamado também
       */
      this.updateCollectionData();
    }
  },
  methods: {
    /**
     * @param {object[]} collection
     * @returns {object[]} retorna o mesmo objeto que recebeu, só que alterado
     */
    fillWithPlaceholder (collection) {
      while (collection.length < this.maxPerPage) {
        collection.push({
          id: null,
          nome_do_ator: '',
          tipo_do_ator: ''
        });
      }
      return collection;
    },
    /**
     * Atualiza os dados da listagem de registros que está
     * sendo visualizada
     *
     * @returns {Promise<void>}
     */
    async updateCollectionData () {
      const collection = await AtoresRepository.getAll({
        page: this.collectionPage,
        perPage: this.maxPerPage,
        sortBy: this.sortBy,
        direction: this.direction,
        actorType: this.actorType
      });

      this.fillWithPlaceholder(collection);

      this.collectionData = collection;
    },
    sortById () {
      if (this.sortBy === 'id') {
        this.direction = (this.direction === 'asc')
          ? 'desc'
          : 'asc';
      } else {
        // Valores iniciais
        this.sortBy = 'id';
        this.direction = 'asc';
      }
      this.collectionPage = 1;
      this.updateCollectionData();
    },
    sortByNome () {
      if (this.sortBy === 'nome_do_ator') {
        this.direction = (this.direction === 'asc')
          ? 'desc'
          : 'asc';
      } else {
        // Valores iniciais
        this.sortBy = 'nome_do_ator';
        this.direction = 'asc';
      }

      this.collectionPage = 1;
      this.updateCollectionData();
    },
    prev () {
      if (this.collectionPage > 1) {
        this.collectionPage -= 1;
      }
    },
    next () {
      this.collectionPage++;
    },
    async confirmarDelecao (registro) {
      if (window.confirm(`Deseja realmente deletar permanentemente o registro?\nid: ${registro.id}\nnome: "${registro.nome_do_ator}"`)) {
        registro.deletingState = true;
        const wasDeleted = await AtoresRepository.deleteById(registro.id);
        registro.deletingState = wasDeleted;

        this.updateCollectionData();

        window.alert(
          (wasDeleted)
            ? `O registro de id: ${registro.id}.\nnome: "${registro.nome_do_ator}"\nFoi deletado`
            : 'Houve algum problema e o registro não pode ser deletado'
        );
      }
    },
    handleFilterSelector (event) {
      const value = event.target.value;
      switch (value) {
        case 'ambos':
          this.actorType = 'ambos';
          break;
        case 'fisico':
          this.actorType = 'fisico';
          break;
        case 'juridico':
          this.actorType = 'juridico';
          break;
        default:
          throw new Error(`Opção inválida selecionada: "${value}"`);
      }

      this.collectionPage = 1;
      this.updateCollectionData();
    },
    handleSortSelector (event) {
      const value = event.target.value;
      switch (value) {
        case 'id-desc':
          this.sortBy = 'id';
          this.direction = 'desc';
          break;
        case 'id-asc':
          this.sortBy = 'id';
          this.direction = 'asc';
          break;
        case 'nome_do_ator-asc':
          this.sortBy = 'nome_do_ator';
          this.direction = 'asc';
          break;
        case 'nome_do_ator-desc':
          this.sortBy = 'nome_do_ator';
          this.direction = 'desc';
          break;
        default:
          throw new Error(`Opção inválida selecionada: "${value}"`);
      }

      this.collectionPage = 1;
      this.updateCollectionData();
    },
    toggleOptions (event) {
      event.target.parentElement.classList.toggle('active');
    },
    
  },
  computed: {
    searchBy () {
        let collectionData = [];
        return collectionData = this.collectionData.filter(search => {return search.nome_do_ator.toLowerCase().includes(this.search.toLowerCase())});
    },
  },
  created () {
    this.updateCollectionData();
    this.collectionData = this.fillWithPlaceholder([]);
  }
};
</script>

<style scoped>
.th-01 {
  width: 115px;
}
.th-02 {

  width: 40%;
}
.th-03 {
  width: 140px;
}
.sortable {
  cursor: pointer;
}
.sortable::after {
  content: close-quote;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/sort-icon.svg');
  background-position: center;
  background-repeat:  no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-left: .4em;
}
.options-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: right;
  position: relative;
}
.options-wrapper:hover .options-list {
  display: block;
}
/* .options-wrapper.active .options-list {
  display: block;
} */
/* .options-wrapper.active .btn-options, */
.options-wrapper:hover .btn-options {
  z-index: 3;
}
.btn-options {
  z-index: 1;
}
.options-list {
  display: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 2;
  padding: 37px 3px 2px;

  width: 220px;
  height: fit-content;

}
.light-blue-organizacao{
  background-color: #6DCFF6 !important;
  color: #2C3A65;
}
.blue-pessoa-fisica{
  background-color: #048090 !important;
  color: white !important;
}
.btn-dark-blue{
  background-color: #2C3A65;
  color: white;
}
.busca-lista{
  width: auto;
  min-width: 400px;
  background-image: url('../../assets/icons/search.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: center;
}
h1{
  font-size: 22px;
  font-weight: bolder;
}
h1+span{
  font-size: 14px;
  color: #717171;
}
select#ordenacao {
  margin-right: 17px;
}
/* .vh-100-50 {
    height: calc(100vh - 50px) !important;
} */
.titulo-pag{
  font-size: 14px;
  font-weight:600;
  color: #0A85CA;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.status-plano{
  background-color: #C6D92D;
  padding: 2px 8px 2px 8px;
  border-radius: 20px;
  font-size: 12px;
}
</style>
